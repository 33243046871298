import { NgModule, Inject } from '@angular/core';

import { RouterModule } from '@angular/router';
import { MenuComponent } from './menu/menu.component';
import { MaterialModule } from './matrial.module';
import { NewIdService } from './utils/services/NewIdService';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { NewIdModel } from './utils/NewIdModel';
const STORAGE_KEY = 'customer';
@NgModule({
  imports: [MaterialModule],
  exports: [
    MenuComponent,
    HeaderComponent,
    FooterComponent,
  ],
  providers: [NewIdService],
  declarations: [MenuComponent, HeaderComponent, FooterComponent]
})
export class SharedContainerModule {

  constructor(@Inject(SESSION_STORAGE) public storage: StorageService) {

  }
  public doSomethingAwesome(data: NewIdModel) {

    this.storage.set(STORAGE_KEY, data);
  }

}
