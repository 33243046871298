import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [

  { path: '', loadChildren: '../app/home/home.module#HomeModule'},
  {
    path: 'newid',
    loadChildren: '../app/newid/newid.module#NewIdModule'
  },
  {
    path: 'newid2',
    loadChildren: '../app/newid2/newid2.module#NewId2Module'
  },
  {
    path: 'payment',
    loadChildren: '../app/payment/payment.module#PaymentModule'
  },
  {
    path: 'photo',
    loadChildren: '../app/photo/photo.module#PhotoModule'
  },
  {
    path: 'confirmation',
    loadChildren: '../app/confirmation/confirmation.module#ConfirmationModule'
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
