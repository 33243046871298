import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class NewIdModel {
	firstName?;
	lastName?;
	ssn?;
	email?;
	streetAddress?;
	zipCode?;
	city?;
	mailAddress?;
	dateBirth?;
	phone?;
	sex?;
	tutor?;
	weight?;
	height?;
	eyeColor?;
	diseases: [];
	takeMedication?;
	files?;
	smoke?;
	drink?;
	information?;
	male?;
	female?;
	na?;
	tokenId?;
	photo1?;
	photo2?;
	photo3?;
	photoType1?;
	photoType2?;
	photoType3?;
	photoUrl1?;
	photoUrl2?;
	photoUrl3?;
	step1: Boolean = false;
	step2: Boolean = false;
	step3: Boolean = false;
	step4: Boolean = false;
	licenciaStatus?;
	cardNum?;
	cardMonth?;
	cardYear?;
	cardCCV?;
	randomKey?;
	paymentStatus?;
}
