import { NgModule } from '@angular/core';

import {
  MatTableModule, MatDialogModule, MatFormFieldModule, MatButtonModule,
  MatInputModule, MatSelectModule, MatIconModule, MatRadioButton, MatRadioModule, MatCheckboxModule, MatCard, MatCardContent, MatCardModule
} from '@angular/material';
import { FormsModule } from '@angular/forms';
@NgModule({
  imports: [
    MatTableModule,
    MatDialogModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    MatSelectModule,
    MatIconModule,
    MatRadioModule,
    MatCheckboxModule,
    MatCardModule
  ],
  exports: [
    MatTableModule,
    MatDialogModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatRadioModule,
    MatCheckboxModule,
    MatCardModule
  ]
})

export class MaterialModule { }
