import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  public login: Boolean = false;

  constructor(private router: Router) { }

  ngOnInit() {



  }

  employeeClick() {
    this.router.navigateByUrl('/employee_list');
  }

  groupsClick() {
    this.router.navigateByUrl('/group_list');
  }

  geoClick() {
    this.router.navigateByUrl('/geo_list');
  }

  logoutClick() {
    this.router.navigateByUrl('/');
  }

}
