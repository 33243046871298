import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AgmCoreModule } from '@agm/core';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WebStorageModule } from 'ngx-store';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { WebcamModule } from 'ngx-webcam';
import { NgxLoadingModule } from 'ngx-loading';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './matrial.module';
import { SharedContainerModule } from './shared_container.module';
import {NgxMaskModule, IConfig} from 'ngx-mask';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import {NgxImageCompressService} from 'ngx-image-compress';
export let options: Partial<IConfig> | (() => Partial<IConfig>);
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [BrowserModule, AppRoutingModule, FormsModule, HttpModule, ToastrModule.forRoot(), WebcamModule, SharedContainerModule,
    WebStorageModule, BrowserAnimationsModule, NgxLoadingModule.forRoot({}), HttpClientModule, MaterialModule,
    NgxMaskModule.forRoot(),
    MaterialFileInputModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      // please get your own API key here: https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
      apiKey: 'AIzaSyBVnW1Z3yVC1_eYaoOgl0rqkARuahuJV04',
      libraries: ['geometry']
    })],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
