import { Component } from '@angular/core';
import { NewIdModel } from './utils/NewIdModel';
import { ToastrService } from 'ngx-toastr';
import { SharedContainerModule } from './shared_container.module';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(public dat: NewIdModel, private toastr: ToastrService, private shared: SharedContainerModule) {
    // dat.city = 'Luquillo';
  }

  public showSuccess(msg) {
    this.toastr.success(msg, 'Alerta');
  }

  public showError(msg) {
    this.toastr.error(msg, 'Alerta');
  }

  public resetFields() {
    this.dat.firstName = null;
    this.dat.lastName = null;
    this.dat.ssn = null;
    this.dat.email = null;
    this.dat.streetAddress = null;
    this.dat.zipCode = null;
    this.dat.city = null;
    this.dat.mailAddress = null;
    this.dat.dateBirth = null;
    this.dat.phone = null;
    this.dat.sex = null;
    this.dat.tutor = null;
    this.dat.weight = null;
    this.dat.height = null;
    this.dat.eyeColor = null;
    this.dat.diseases = [];
    this.dat.takeMedication = null;
    this.dat.files = null;
    this.dat.smoke = null;
    this.dat.drink = null;
    this.dat.information = null;
    this.dat.male = null;
    this.dat.female = null;
    this.dat.na = null;
    this.dat.tokenId = null;
    this.dat.photo1 = null;
    this.dat.photo2 = null;
    this.dat.photoType1 = null;
    this.dat.photoType2 = null;
    this.dat.photoUrl1 = null;
    this.dat.photoUrl2 = null;
    this.dat.step1 = false;
    this.dat.step2 = false;
    this.dat.step3 = false;
    this.dat.step4 = false;
  }

  title = 'licenciafacil';
}
