// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	// urlLink: 'http://localhost:3000/api',
	urlLink: 'https://licencia-facil-staging.herokuapp.com/api',
	stripeKey: 'pk_test_EdpiYSLv8DLnDqk7x6UazrGx00NalquyQh',
};
